import * as React from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

export const useUserData = ({ userUid }) => {
  const [loading, setLoading] = React.useState(true)
  const [accountCreated, setAccountCreate] = React.useState(false)
  const [userData, setUserData] = React.useState(null)
  React.useEffect(() => {
    const checkAccount = async () => {
      const db = firebase.firestore()
      const docRef = db.collection('users').doc(userUid)
      const doc = await docRef.get()
      if (doc.exists) {
        setAccountCreate(true)
        setUserData(doc.data())
      }
      setLoading(false)
    }
    try {
      checkAccount()
    } catch (e) {
      console.log(e)
    }
  }, [])

  return {
    loading,
    accountCreated,
    userData
  }
}
