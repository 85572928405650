import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

export const LoadingFullPage = () => (
  <LoadingWrapper>
    <CircularProgress color="secondary" />
  </LoadingWrapper>
)

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`
