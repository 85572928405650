import * as React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

export const useCheckLogin = () => {
  const [user, setUser] = React.useState(null)
  const [authenticated, setAuthenticated] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const firebaseConfig = {
    apiKey: 'AIzaSyDlCr2ucljO6XIdOrrjM2svjsmLqXDwlJk',
    authDomain: 'snoot-app.firebaseapp.com',
    databaseURL: 'https://snoot-app.firebaseio.com',
    projectId: 'snoot-app',
    storageBucket: 'snoot-app.appspot.com',
    messagingSenderId: '344478972568',
    appId: '1:344478972568:web:f865b213bb01cbb4ffeeed',
    measurementId: 'G-ZDCL6PKBJS'
  }
  !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app()
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          setUser(user)
          setAuthenticated(true)
        } else {
          setAuthenticated(false)
          setUser(null)
        }
      },
      (error) => {
        console.log(error)
      },
      setLoading(false)
    )
  }, [])
  return {
    user,
    authenticated,
    loading
  }
}
