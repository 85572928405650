import * as React from 'react'
import styled from 'styled-components'
import { Typography, makeStyles } from '@material-ui/core'
import firebase from 'firebase/app'
import Button from '@material-ui/core/Button'
import FacebookIcon from '@material-ui/icons/Facebook'
import Glogo from '../assets/glogo.png'

import PuppyKittenImage from '../assets/Puppy-and-Kitten.png'

export const Login = () => {
  const classes = useStyles()
  const handleGgoogleLogin = () => {
    firebase.app()
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithRedirect(googleProvider)
    firebase.auth().getRedirectResult()
  }
  const handleFbLogin = () => {
    firebase.app()
    const fbProvider = new firebase.auth.FacebookAuthProvider()
    fbProvider.addScope('public_profile')
    fbProvider.addScope('email')
    firebase.auth().signInWithRedirect(fbProvider)
    firebase.auth().getRedirectResult()
  }

  return (
    <Wrapper>
      <Header>
        <Typography variant="h3" color="secondary">
          SNOOT
        </Typography>
      </Header>
      <Hero>
        <img src={PuppyKittenImage} alt="Puppy and Kitten" />
      </Hero>
      <ButtonsWrapper>
        <Button
          className={classes.fbButton}
          variant="contained"
          size="large"
          startIcon={<FacebookIcon />}
          onClick={() => handleFbLogin()}
        >
          Sign in with Facebook
        </Button>
        <Button
          className={classes.googleButton}
          variant="contained"
          size="large"
          startIcon={<GoogleLogo src={Glogo} />}
          onClick={() => handleGgoogleLogin()}
        >
          Sign in with Google
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

const useStyles = makeStyles({
  fbButton: {
    width: '100%',
    maxWidth: '350px',
    backgroundColor: '#3b5998',
    letterSpacing: 1.25,
    color: 'white',
    '&:hover': {
      backgroundColor: '#3b5998',
      opacity: 0.8
    }
  },
  googleButton: {
    width: '100%',
    maxWidth: '350px',
    backgroundColor: 'white',
    color: 'black',
    letterSpacing: 1.25
  }
})

const Header = styled.div`
  padding-top: 20px;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Hero = styled.div`
  width: 100%;
  max-width: 500px;
  img {
    width: 100%;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    margin-bottom: 16px;
  }
`

const GoogleLogo = styled.img`
  width: 18px;
`
