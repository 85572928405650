// @flow

import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { Home } from '../home/index'
import { Onboarding } from '../onboarding'
import { useUserData } from '../hooks/useGetUserData'
import { LoadingFullPage } from '../components/loading'

export const AuthenticatedRoutes = ({ user }: any): any => {
  const { loading, accountCreated } = useUserData({ userUid: user.uid })

  if (loading) return <LoadingFullPage />
  if (accountCreated) {
    return (
      <Route exact path="/">
        <Home user={user} />
      </Route>
    )
  }
  return (
    <>
      <Route exact path="/onboarding" component={Onboarding} />
      <Redirect to={{ pathname: '/onboarding' }} />
    </>
  )
}
