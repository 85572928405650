import { BrowserRouter as Router, Route } from 'react-router-dom'
import * as React from 'react'

import { Login } from './login'
import { useCheckLogin } from './hooks/useCheckLogin'
import { AuthenticatedRoutes } from './routes/authenticated'
import { Profile } from './profile'

export const AppRoutes = () => {
  const { user, loading, authenticated } = useCheckLogin()
  console.log(user, loading, authenticated)
  // TO DO handle 404 and errors
  return loading === false ? (
    <Router>
      {authenticated === true ? <AuthenticatedRoutes user={user} /> : null}
      {authenticated === false ? (
        <>
          <Route exact path="/" component={Login} />
          <Route exact path="/milo" component={Profile} />
        </>
      ) : null}
    </Router>
  ) : null
}
