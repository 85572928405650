import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import { colors } from './colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.primary
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: colors.secondary,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00'
    },
    facebook: {
      main: colors.fbBlue
    }
    // error: will use the default color
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: '20px'
      }
    }
  }
})

export default responsiveFontSizes(theme)
