import * as React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import 'normalize.css'
import 'sanitize.css'

import { GlobalStyles } from './styles'
import theme from './styles/theme'
import { AppRoutes } from './routes'

const App = () => {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </>
  )
}

export default App
