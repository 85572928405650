import { createGlobalStyle } from 'styled-components'

//removes the default browser outline when elements are focused unless it is focused with a keyboard to make it accessible
export const GlobalStyles = createGlobalStyle`
  html {
    &:not([data-whatintent='keyboard']) *:focus {
      outline: none;
    }
  }
  .fb-login-button {
      width: 100%;
      max-width: 400px;
  }
`
