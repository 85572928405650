// @flow

import * as React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import firebase from 'firebase/app'

import { useUserData } from '../hooks/useGetUserData'
import { LoadingFullPage } from '../components/loading'

export const Home = ({ user }: any): any => {
  const { loading, userData } = useUserData({ userUid: user?.uid })
  if (loading) return <LoadingFullPage />
  const logOut = () => {
    firebase.app()
    firebase.auth().signOut()
  }
  return (
    <>
      <Typography variant="h1">Hello {userData['pet-name']}!</Typography>
      <Typography variant="h3">From{userData['city']}</Typography>
      <ImageProfile src={userData['pet-profile-photo-url']} />
      <Button variant="contained" onClick={logOut}>
        Log Out
      </Button>
    </>
  )
}

const ImageProfile = styled.img`
  width: 100%;
`
