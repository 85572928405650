import * as React from 'react'
import firebase from 'firebase/app'
import Select from 'react-select'
import {
  Typography,
  TextField,
  InputLabel,
  makeStyles
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Fab from '@material-ui/core/Fab'
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'

import { DogsList } from '../data/dogsList'
import { useCheckLogin } from '../hooks/useCheckLogin'
import PetEmptyAvatar from '../assets/petEmptyAvatar.png'
import { LoadingFullPage } from '../components/loading'

export const Onboarding = () => {
  const { control, handleSubmit, errors, register } = useForm()
  const { user } = useCheckLogin()
  const [petProfileImageUrl, setPetProfileImageUrl] = React.useState(null)

  const options = DogsList.map((dog) => ({ value: dog, label: dog }))
  const classes = useStyles()
  const logOut = () => {
    firebase.app()
    firebase.auth().signOut()
  }

  const handleCreateAccount = ({ userName, petName, city, country, breed }) => {
    firebase.app()
    const database = firebase.firestore()
    database
      .collection('users')
      .doc(user.uid)
      .set(
        {
          'user-name': userName,
          'pet-name': petName,
          city,
          country,
          breed: breed.value
        },
        { merge: true }
      )
      .then(function () {
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  const handleImageUpload = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      firebase.app()
      const filePicker = document.querySelector('#pet-image-input')

      if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
        reject('No file selected.')
        return
      }
      const myFile = filePicker.files[0]
      if (myFile.size > 10485760) {
        reject('Image is too big (max. 10 Mb)')
        return
      }
      try {
        const storagePathAndFilename = `${user.uid}/profilePic/${myFile.name}`
        const ref = firebase.storage().ref(storagePathAndFilename)

        await ref.put(myFile)
        const myDownloadUrl = await ref.getDownloadURL()
        const database = firebase.firestore()
        database
          .collection('users')
          .doc(user.uid)
          .set(
            {
              'pet-profile-photo-url': myDownloadUrl
            },
            { merge: true }
          )
          .then(function () {
            console.log('Document successfully written!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
        setPetProfileImageUrl(myDownloadUrl)
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  }
  if (!user) {
    return <LoadingFullPage />
  }

  return (
    <Wrapper>
      <ImageWrapper>
        {petProfileImageUrl ? (
          <PetProfileImage
            src={petProfileImageUrl}
            alt="Pet Profile Photo"
          ></PetProfileImage>
        ) : (
          <PetsAvatarEmpty>
            <input
              className={classes.input}
              id="pet-image-input"
              type="file"
              accept="image/x-png,image/jpeg,image/gif"
              onChange={() => handleImageUpload()}
            />
            <label htmlFor="pet-image-input">
              <Fab color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </Fab>
            </label>
          </PetsAvatarEmpty>
        )}
        <UserImageWrapper>
          <Header>
            <Typography variant="h3" color="secondary">
              Welcome!
            </Typography>
          </Header>
          <UserImage
            src={
              user.photoURL ? user.photoURL : 'https://via.placeholder.com/150'
            }
            alt={`image of ${user.displayName ? user.displayName : ''}`}
          />
        </UserImageWrapper>
      </ImageWrapper>
      <Form
        id="profile"
        onSubmit={handleSubmit(handleCreateAccount)}
        errors={errors}
      >
        <TextField
          name="petName"
          id="pet-name"
          label="Furbaby Name*"
          error={!!errors.petName}
          inputRef={register({
            required: { value: true, message: 'Required' },
            maxLength: { value: 200, message: 'Name is too long.' }
          })}
          helperText={errors.petName ? errors.petName.message : ''}
        />
        <TextField
          name="userName"
          id="user-name"
          label="Human Name*"
          error={!!errors.userName}
          defaultValue={user.displayName ? user.displayName : undefined}
          inputRef={register({
            required: { value: true, message: 'Required' },
            maxLength: { value: 200, message: 'Name is too long.' }
          })}
          helperText={errors.userName ? errors.userName.message : ''}
        />
        <InputLabel
          className="breed-label"
          htmlFor="breed-select"
          error={!!errors.breed}
        >
          Breed*
        </InputLabel>
        <Controller
          as={
            <Select
              id="breed-select"
              className="breed-select"
              options={options}
            />
          }
          control={control}
          rules={{ required: true }}
          onChange={([selected]) => {
            return { value: selected }
          }}
          name="breed"
        />

        <TextField
          name="city"
          id="city"
          label="City*"
          error={!!errors.city}
          inputRef={register({
            required: { value: true, message: 'Required' },
            maxLength: { value: 200, message: 'Name is too long.' }
          })}
          helperText={errors.city ? errors.city.message : ''}
        />
        <TextField
          name="country"
          id="country"
          label="Country*"
          error={!!errors.country}
          inputRef={register({
            required: { value: true, message: 'Required' },
            maxLength: { value: 200, message: 'Name is too long.' }
          })}
          helperText={errors.country ? errors.country.message : ''}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          form="profile"
        >
          Create Account
        </Button>
      </Form>
      <Cancel onClick={logOut}>
        <Typography variant="body2" color="secondary">
          cancel
        </Typography>
      </Cancel>
    </Wrapper>
  )
}

const useStyles = makeStyles({
  input: {
    display: 'none'
  }
})

const Header = styled.div`
  padding-top: 20px;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const UserImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

const PetsAvatarEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  border: 1px solid lightgrey;
  border-radius: 50%;
  background-image: url(${PetEmptyAvatar});
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
`

const PetProfileImage = styled.img`
  width: 125px;
  height: 125px;
  border: 1px solid lightgrey;
  border-radius: 50%;
`

const UserImage = styled.img`
  border-radius: 50%;
  width: 50px;
  margin-top: 8px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
  .breed-label {
    padding-bottom: 6px;
  }
  .breed-select {
    margin-bottom: 10px;
    > div {
      border-color: rgba(0, 0, 0, 0.42);
    }
    font-family: 'roboto', 'helvetica', 'Arial';
    font-size: 16px;
    svg {
      fill: rgba(0, 0, 0, 0.42);
    }
  }
  ${({ errors }) =>
    errors.breed &&
    `
      .breed-select {
        > div {
          border-color: red;
        }
      }
      `}
`

const Cancel = styled.div`
  margin-top: 8px;
  cursor: pointer;
`
