import * as React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Profile = () => {
  return (
    <Wrapper>
      <Header>
        <Typography variant="h3" color="secondary">
          Milo Wah
        </Typography>
      </Header>
      <Title>Feeding:</Title>
      <List>
        <li>Once in morning - 1/2 cups</li>
        <li>Once in evening after work - 1/2 cups</li>
        <li>Measuring Cup provided</li>
        <li>Stomach sensitivity, feed only fish based, sweet potatoe treats.</li>
        <li>Can have limited amount of blueberries, raspberries, strawberries and carrots.</li>
        <li>Feed only 2 - 3 treats a day as he is on a diet</li>
      </List>
      <Title>Potty Breaks and walks:</Title>
      <List>
        <li>Once in the morning</li>
        <li>Once during lunch time (anytime between noon to 2)</li>
        <li>Once after work (around 6-7pm)</li>
        <li>Quick pee break around 9-10pm</li>
      </List>
      <Title>Vet:</Title>
      <List>
        <li>Daytime Vet: Bay Cat and Dog Hospital</li>
        <li>
          Address:{' '}
          <a href="https://goo.gl/maps/Qb9aQVKn34TuGBxy9">
            525 King Street E, Toronto
          </a>
        </li>
        <li>
          Phone: <a href="tel:4169418920">416-941-8920</a>
        </li>
        <li>Emergency Vet: Veterinary Emergency Clinic</li>
        <li>
          Address:{' '}
          <a href="https://goo.gl/maps/TKxXV3E6n1JPzPrM7">
            920 Yonge St Suite 117, Toronto, ON M4W 3C7
          </a>
        </li>
        <li>
          Phone: <a href="tel:4169202002">416-920-2002</a>
        </li>
        <li>
          You can call the vet anytime with questions and they won't charge you.
          If you doubt, call the vet.
        </li>
        <strong>
          <li>
            Do not let Milo eat any of the toxic foods: Grapes, Rasins, Chocolate.
            If accidental ingestion occurs, take him to the Vet!
          </li>
        </strong>
      </List>
    </Wrapper>
  )
}

const Header = styled.div`
  padding-top: 20px;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  max-width: 300px;
`

const Title = styled.h2`
  margin: 0px;
  padding: 0px;
  padding-top: 16px;
`
